* {
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: copperplate;
  src: url("./asset/font/copperplate_regular-webfont.eot");
  src: url("./asset/font/copperplate_regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./asset/font/copperplate_regular-webfont.woff2") format("woff2"),
    url("./asset/font/copperplate_regular-webfont.woff") format("woff"),
    url("./asset/font/copperplate_regular-webfont.ttf") format("truetype"),
    url("./asset/font/copperplate_regular-webfont.svg#svgFontName")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

html {
  margin: auto 0;
  scroll-behavior: smooth;
  width: 100%;
  font-family: Copperplate, copperplate;
}
body {
  display: flex;
  font-family: Copperplate, copperplate;
  background: #0f0f0f;
}

#root {
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  max-width: 1300px;
  width: 100%;
  font-family: Copperplate, copperplate;
}
.primaryColor {
  background-image: linear-gradient(180deg, #00936e 0%, #00ffe0 100%);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h1 {
  background-image: linear-gradient(180deg, #00936e 0%, #00ffe0 100%);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}
button {
  cursor: pointer;
}
.borderGradient {
  position: relative;
  padding: 15px 10px;
  margin: 50px 30px;
  display: inline-block;
  border: none;
}

.borderGradient:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 15px !important;
  padding: 2px;
  background: linear-gradient(180deg, #00936e 0%, #00ffe0 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.footer {
  text-align: center;
  padding-top: 50px;
}
.footer img {
  width: 100%;
  padding-bottom: 20px;
}
.footer span {
  padding-bottom: 20px;
  font-size: 14px;
}
.stickyButton {
  position: -webkit-sticky;
  position: sticky;
  top: 70%;
  z-index: 6;
  background: transparent;
  color: transparent;
}

.stickyButton button {
  background: transparent;
  color: transparent;
  border: none;
  margin: 50px;
}
.stickyButton button:hover {
  -webkit-transform: scale(1.3, 1.3);
}
.hide {
  display: none;
}
.show {
  display: block;
}
@keyframes colors {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

@media screen and (max-width: 900px) {
  .stickyHeader {
    position: -webkit-sticky;
    position: sticky;
    top: -10px;
    z-index: 6;
  }
  .headerItem {
    position: absolute;
    right: 15px;
  }
  .footer span {
    font-size: 10px;
  }
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  background-image: linear-gradient(180deg, #00936e 0%, #00ffe0 100%);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  background-image: linear-gradient(180deg, #00936e 0%, #00ffe0 100%);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  background-image: linear-gradient(180deg, #00936e 0%, #00ffe0 100%);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo {
  position: absolute;
  padding: 3rem 0 0 3rem;
}

.profileDiv {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  padding-top: 6rem;
}
.profileImg {
  margin-left: auto;
  margin-right: 0;
  position: relative;
}
.profileImg div {
  width: 200px;
}
.profileImg img {
  width: 200px;
  border-radius: 15px;
}
.Intro {
  position: absolute;
  bottom: 0px;
  width: 230px;
  height: 230px;
  background: linear-gradient(180deg, #010101 0%, rgba(0, 0, 0, 0.52) 100%);
  backdrop-filter: blur(20px);
  overflow: hidden;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
/* .profileImg:hover .Intro {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  cursor: pointer;
} */
.Intro p {
  color: white;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
}
.profileDiv div > h1 {
  padding-top: 4rem;
  font-size: 20px;
  cursor: pointer;
}
.profileDiv div > p {
  padding-top: 1rem;
  font-size: 18px;
  cursor: pointer;
}
.buttonDiv,
.contactIconDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonDiv button {
  background-color: transparent;
  font-family: Copperplate, Papyrus, fantasy;
  width: 140px;
  font-size: 10px;
}
.buttonDiv button:hover {
  -webkit-transform: scale(1.1, 1.1);
}

.contactIcon {
  display: flex;
  width: 300px;
  justify-content: space-between;
}
.contactIcon a > img {
  width: 30px;
}
@media screen and (max-width: 900px) {
  .logo {
    position: absolute;
    padding: 2rem 0 0 1.5rem;
    width: 60px;
  }
  .profileDiv {
    display: block;
    clear: both;
    padding-top: 6rem;
  }

  .contactIcon {
    display: flex;
    width: 250px;
    justify-content: space-between;
  }

  .profileImg {
    margin-top: 30px;
    margin: 0 auto;
    width: 200px;
  }
  .profileImg img {
    width: 200px;
    margin: 0 auto;
    border-radius: 15px;
  }

  .Intro {
    position: absolute;
  }

  .profileDiv div > h1 {
    padding-top: 2rem;
    text-align: center;
    font-size: 20px;
  }
  .profileDiv div > p {
    text-align: center;
    padding-top: 1rem;
    font-size: 16px;
  }
  .buttonDiv {
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto;
  }
  .buttonDiv button {
    background-color: transparent;
    font-family: Copperplate, Papyrus, fantasy;
    width: 120px;
    font-size: 10px;
    margin: 50px 10px;
  }
}

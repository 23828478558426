.contactForm {
  position: relative;
}
.contactForm h1 {
  text-align: center;
  font-size: 20px;
}
.rigthLign {
  position: absolute;
  right: 80px;
  top: -145px;
  z-index: 2;
  width: 350px;
  animation: rigthLign 5s;
  animation-direction: alternate;
  animation-iteration-count: 5;
}
@keyframes rigthLign {
  from {
    transform: rotate(0deg);
    width: 350px;
  }
  to {
    transform: rotate(360deg);
    width: 250px;
    right: 80px;
    top: -170px;
  }
}
.contactMe {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  background-color: transparent;
}

.contactDiv {
  padding-top: 60px;
}
.contactDiv input {
  color: rgba(5, 163, 143, 1);
  font-family: Copperplate, Papyrus, fantasy;
  font-size: 10px;
  border: 1px solid rgba(5, 163, 143, 1);
  background: transparent;
  width: 250px;
  max-width: 250px;
  height: 20px;
  border-radius: 20px;
  padding: 15px 30px;
  margin-bottom: 20px;
}
.contactDiv textarea {
  color: rgba(5, 163, 143, 1);
  font-size: 10px;
  font-family: Copperplate, Papyrus, fantasy;
  border: 1px solid rgba(5, 163, 143, 1);
  background: transparent;
  width: 250px;
  max-width: 250px;
  height: 200px;
  border-radius: 20px;
  padding: 15px 30px;
}
.sendButtonDiv button {
  font-family: Copperplate, Papyrus, fantasy;
  width: 250px;
}
.activeButton {
  background-color: transparent;
  position: relative;
  padding: 15px 10px;
  margin: 50px 30px;
  display: inline-block;
  border: none;
}

.activeButton:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 15px !important;
  padding: 2px;
  background: linear-gradient(180deg, #00936e 0%, #00ffe0 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.activeButton:hover {
  -webkit-transform: scale(1.1, 1.1);
}
.inactiveButton {
  cursor: default;
  background-color: transparent;
  position: relative;
  padding: 15px 10px;
  margin: 50px 30px;
  display: inline-block;
  border: 1px solid #8b8b8b;
  border-radius: 15px;
}
.inactiveText {
  background-image: linear-gradient(180deg, #8b8b8b, #a3a3a3);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.activeText {
  background-image: linear-gradient(180deg, #00936e 0%, #00ffe0 100%);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 900px) {
  .contactForm h1 {
    padding-top: 2em;
    margin-left: 2rem;
    font-size: 20px;
    text-align: left;
  }
  .rigthLign {
    position: absolute;
    width: 170px;
    right: 0px;
    top: -45px;
    z-index: 2;
    animation: pause;
  }

  .contactMe {
    display: block;
    position: relative;
    justify-content: center;
    background-color: transparent;
    padding: 0;
  }
  .contactMe div {
    margin: 0 auto;
  }
  .contactMe img {
    display: none;
  }
  .contactDiv {
    z-index: 3;
    justify-content: center;
    text-align: center;
  }
  .contactDiv input {
    width: 200px;
  }
  .contactDiv textarea {
    width: 200px;
    max-width: 200px;
  }
}

.projectView {
  position: relative;
  margin: 100px 0px;
}
.projectView h1 {
  text-align: center;
  font-size: 20px;
}

.rigthLign {
  position: absolute;
  right: 80px;
  top: -145px;
  z-index: 2;
  width: 350px;
  animation: rigthLign 5s;
  animation-direction: alternate;
  animation-iteration-count: 5;
}
@keyframes rigthLign {
  from {
    transform: rotate(0deg);
    width: 350px;
  }
  to {
    transform: rotate(360deg);
    width: 50px;
    right: 80px;
    top: -170px;
  }
}
.flexDiv {
  display: flex;
  justify-content: center;
}

.currentProject {
  width: 900px;
  height: 300px;
  max-height: 330px;
  display: grid;
  padding-bottom: 6em;
  grid-template-columns: repeat(2, 1fr);
}
.projectTitle h1 {
  font-size: 18px;
}
.projectTitle p {
  width: 260px;
  padding-top: 10px;
  font-size: 14px;
}
.projectProgLang {
  width: 260px;
  padding: 30px 0;
  display: flex;
  justify-content: flex-start;
}
.projectProgLang img {
  width: 30px;
  height: 30px;
  padding-right: 10px;
}
.projectImg {
  position: relative;
  width: 400px;
}
.slideImdShow,
.projectSlider {
  display: flex;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #00936e black;
}
.slideImdShow {
  width: 630px;
  justify-content: space-between;
  margin: 20px 30px;
}

.projectSlider {
  width: 900px;
  height: 180px;
  justify-content: space-evenly;
}
::-webkit-scrollbar {
  background-color: black;
  width: 1em;
}

::-webkit-scrollbar-thumb {
  background-color: #00936e;
  border: 0.25em solid black;
  border-radius: 1.25em;
}
.slideImdShow img {
  width: 480px;
  height: 300px;
  padding-left: 15px;
  cursor: pointer;
}
.projectImg img {
  position: absolute;
  right: -50px;
  width: 600px;
}

.otherProject {
  margin-bottom: 150px;
  position: relative;
}
.desktop {
  width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.projectSlider img {
  width: 230px;
  height: 130px;
  padding: 10px;
  cursor: pointer;
}
.projectSlider img:hover {
  -webkit-transition: 0.1s;
  -webkit-transform: scale(1.1, 1.1);
}

@media screen and (max-width: 1100px) {
  .currentProject {
    width: 800px;
  }
  .slideImdShow {
    width: 530px;
  }
  .projectSlider {
    width: 800px;
  }
  .slideImdShow img {
    width: 400px;
    height: 220px;
    padding-left: 15px;
  }
  .desktop {
    width: 800px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 900px) {
  .projectView {
    position: relative;
    margin: 70px auto;
  }

  .desktop {
    margin: 0px auto;
    width: 300px;
  }

  .rigthLign {
    width: 170px;
    position: absolute;
    right: 0px;
    top: -40px;
    z-index: 2;
    animation: pause;
  }
  .flexDiv {
    height: 370px;
  }
  .currentProject {
    margin: 0 auto;
    width: 3000px;
    grid-template-columns: auto;
  }
  .projectTitle {
    width: 210px;
    margin: 0px auto;
  }

  .otherProject {
    margin-bottom: 100px;
    position: relative;
    width: 260px;
    margin: 0px auto;
  }

  .projectTitle p {
    width: 210px;
    font-size: 14px;
  }
  .projectView h1 {
    padding-top: 2em;
    padding-left: 2rem;
    font-size: 20px;
    text-align: left;
  }

  .slideImdShow,
  .projectSlider {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0 auto;
    width: 260px;
    height: 190px;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: #00936e black;
  }
  ::-webkit-scrollbar {
    background-color: black;
    width: 1em;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #00936e;
    border: 0.25em solid black;
    border-radius: 1.25em;
  }
  .slideImdShow img,
  .projectSlider img {
    width: 260px;
    height: 170px;
    margin: 0 auto;
    padding: 0;
  }
  .projectSlider img:hover {
    -webkit-transform: scale(1, 1);
  }
}

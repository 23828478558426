.competence {
  position: relative;
  margin-bottom: 50px;
}
.competence h1 {
  text-align: center;
  font-size: 20px;
}
.leftLign {
  position: absolute;
  left: 80px;
  top: -135px;
  z-index: 2;
  width: 350px;
  animation: leftLign 5s;
  animation-direction: alternate;
  animation-iteration-count: 5;
}

@keyframes leftLign {
  from {
    transform: rotate(0deg);
    width: 350px;
  }
  to {
    transform: rotate(360deg);
    width: 250px;
    top: -170px;
  }
}
.competenceDiv {
  display: grid;
  grid-template-columns: auto auto auto;
  width: 700px;
  gap: 20px 5px;
  justify-content: center;
  padding-top: 50px;
  margin: 0 auto;
}

.competenceSection {
  height: 130px;
  margin: auto 0;
}
.competenceSection:hover {
  -webkit-transform: scale(1.2, 1.2);
}
.competenceItem {
  width: 200px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.competenceSubItem {
  display: flex;
  margin-left: 25px;
}
.competenceSubItemImg {
  width: 30px;
  padding: 2px;
}
.competenceItemImg {
  width: 60px;
}
.line hr {
  height: 1px;
  width: 30px;
  border: 0;
  background: #00936e;
}
.circle {
  display: flex;
}
.circle span {
  height: 8px;
  width: 8px;
  margin: 2px;
  border-radius: 50%;
  display: inline-block;
}
.circleColor1 {
  background-color: #00ffe0;
}
.circleColor2 {
  background-color: #00936e;
}

@media screen and (max-width: 900px) {
  .competence h1 {
    padding-top: 2em;
    margin-right: 2rem;
    font-size: 20px;
    text-align: right;
  }
  .leftLign {
    position: absolute;
    left: 0px;
    top: -40px;
    z-index: 2;
    width: 170px;
    animation: pause;
  }

  .competenceDiv {
    display: grid;
    grid-template-columns: auto auto;
    width: 300px;
    gap: 10px 5px;
    justify-content: center;
    margin: 0 auto;
  }
  .competenceSection {
    height: 130px;
    margin: auto 0;
  }
  .competenceSection:hover {
    -webkit-transform: scale(1, 1);
  }

  .competenceItem {
    width: 150px;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .competenceItemImg {
    width: 50px;
  }
  .competenceSubItem {
    margin-left: 16px;
  }
  .competenceSubItemImg {
    width: 30px;
    padding: 2px;
  }
  .line hr {
    height: 1px;
    width: 20px;
    border: 0;
    background: #00936e;
  }
  .circle span {
    height: 7px;
    width: 7p1;
    margin: 1px;
    border-radius: 50%;
    display: inline-block;
  }
}
